import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import carbon from '../assets/images/carbon-fiber.jpg';
import products from '../assets/images/products.jpg';
import support from '../assets/images/support.jpg';

const NavPagesStyled = styled.section`
  background: var(--backgroundCard);
  text-align: center;
  padding: 4rem 1rem;
  margin: 4rem 0 -1rem;
  ul.page-links {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    margin: 2rem 0 0;
    padding: 0;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
      padding: 0rem 1rem;
    }
    li {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 8px;
      overflow: hidden;
      min-height: 400px;
      a {
        border-radius: 8px;
        border: 1px solid var(--borderColor);
        color: var(--textColor);
        text-decoration: none;
        height: 100%;
        width: 100%;
        display: block;
        background: var(--whiteTrans);
        display: grid;
        align-items: center;
        font-family: AktivGrotesk;
        text-transform: uppercase;
        margin: 0;
        line-height: 120%;
        font-size: 3rem;
      }
      @media (max-width: 1023px) {
        min-height: 200px;
        a {
          font-size: 2rem;
        }
      }
    }
    .products {
      background-image: url(${products});
    }
    .technology {
      background-image: url(${carbon});
    }
    .support {
      background-image: url(${support});
    }
  }
`;

export default function NavPages() {
  return (
    <NavPagesStyled>
      <div className="wrapper">
        <h4>More from Bont Cycling</h4>
        <ul className="page-links">
          <li className="products">
            <Link to="/products/">Products</Link>
          </li>
          <li className="technology">
            <Link to="/technology/">Technology</Link>
          </li>
          <li className="support">
            <Link to="/support/">Support</Link>
          </li>
        </ul>
      </div>
    </NavPagesStyled>
  );
}
